export const CANVAS = {
    width: 800,
    height: 800
};

export const BAT_FLAPPING = [
    {x:42, y:34, w:16, h:19},
    {x:107, y:35, w:16, h:21},
    {x:74, y:35, w:16, h:19},
];

export const BAT_DEAD = {x:4, y:47, w:23, h:16};