import React from "react";
import "./footer.css";

const Footer = () => {
    return (
        <footer className="footer">
            <p>&copy; 2024 Vale Games</p>
        </footer>
    )
};

export default Footer;